import { api } from "./api";
import {IGetExplodedDrawingsDto} from "../../models/dto/IGetExplodedDrawingsDto";
import {IExplodedDrawings} from "../../models/entities/IExplodedDrawings";
import {IGetDrawingDto} from "../../models/dto/IGetDrawingDto";

const explodedDrawingApi = api.injectEndpoints({
    overrideExisting: false,
    endpoints: (build) => ({
        getExplodedDrawings: build.query<IGetExplodedDrawingsDto, string>({
            query: (searchString) => ({
                url: "exploded/search",
                params: { string: searchString },
            }),
            transformResponse: (baseQueryReturnValue: IGetExplodedDrawingsDto, meta) =>
                baseQueryReturnValue,
            providesTags: (result) =>
              [{ type: "ExplodedDrawings", id: "ED_List" }],
        }),
        getDrawingPartsOf: build.query<IGetDrawingDto, string>({
            query: (explodeID) => ({
                url: `exploded/drawing/${explodeID}`,
            }),
            transformResponse: (baseQueryReturnValue: IGetDrawingDto, meta) =>
                baseQueryReturnValue,
            providesTags: (result) => [{ type: "DrawingPartsOF", id: "DP_List" }],
        }),
    }),
});

export const {
    useGetExplodedDrawingsQuery,
    useLazyGetExplodedDrawingsQuery,
    useGetDrawingPartsOfQuery,
} = explodedDrawingApi;
