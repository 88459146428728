import {
    Grid,
    GridColumn, GridColumnMenuFilter, GridColumnMenuProps,
    GridFilterChangeEvent,
    GridPageChangeEvent, GridRowClickEvent,
    GridSortChangeEvent
} from "@progress/kendo-react-grid";
import products from "./products.json";
import {CompositeFilterDescriptor, filterBy, orderBy, SortDescriptor} from "@progress/kendo-data-query";
import React, {useEffect, useState} from "react";
import {SCREEN_LG, SCREEN_MD, SCREEN_XL, useWindowSize} from "../utils/ScreenSizes";
import {Button} from "@progress/kendo-react-buttons";
import {GridHeaderCellProps} from "@progress/kendo-react-grid/dist/npm/interfaces/GridHeaderCellProps";
import {GridFilterCellProps} from "@progress/kendo-react-grid/dist/npm/interfaces/GridFilterCellProps";
import {GridCellProps} from "@progress/kendo-react-grid/dist/npm/interfaces/GridCellProps";

interface PageState {
    skip: number;
    take: number;
}
const initialFilter: CompositeFilterDescriptor = {
    logic: "and",
    filters: [],
};

interface ExplodedDrawingsGridProps {
    data: any[],
    onRowClick: (event: GridRowClickEvent) => void;
    maxWidth: string;
}

const customGruppoCell = (props: GridCellProps) => {
    return (
        <td>
            {props.dataItem.gruppo_costruttivo_id} <br></br>
            <span style={{fontSize: "13px"}}>{props.dataItem.gruppo_costruttivo_desc}</span>
        </td>
    );
}

const ExplodedDrawingsGrid = (props: ExplodedDrawingsGridProps) =>{


    const initialDataState: PageState = { skip: 0, take: 20 };
    const [sort, setSort] = useState<SortDescriptor[]>([]);
    const [page, setPage] = useState<PageState>(initialDataState);
    const [filterable, setFilterable] = useState<boolean>(false);
    const [filter, setFilter] = useState(initialFilter);

    const pageChange = (event: GridPageChangeEvent) => {
        setPage(event.page);
    };

    const ColumnMenu = (props: GridColumnMenuProps) => {

        return (
            <div className={"filtro-menu"}>
                <GridColumnMenuFilter

                    {...props}
                    hideSecondFilter={true}
                    expanded={true}
                />
            </div>
        );
    };

    return(
        <>

            <Grid

                className={"articles-grid exploded-drawing-grid"}
                pageable={{
                    buttonCount: 9,
                    info: false,
                    type: "numeric",
                    pageSizes: [10, 25, 50 , 100, 200 ],
                    previousNext: true,
                }}
                sortable={true}
                filterable={filterable}
                sort={sort}
                onSortChange={(e: GridSortChangeEvent) => {
                    setSort(e.sort);
                }}

                filter={filter}
                skip={page.skip}
                take={page.take}
                onPageChange={pageChange}
                // filterable={true}
                style={{height: "80vh", maxWidth: props.maxWidth,}}
                total={props.data.length}
                onFilterChange={(e: GridFilterChangeEvent) => setFilter(e.filter)}
                // columnVirtualization={true}
                onRowClick={(e) => props.onRowClick(e)}
                // headerCellRender={headerWithFilter}
                // headerCellRender={headerWithFilter}
                data={orderBy(filterBy(props.data, filter), sort).slice(page.skip, page.take + page.skip)}
            >

                <GridColumn field="gruppo_costruttivo_id" title="Gruppo Costruttivo" width={300} cell={customGruppoCell} columnMenu={ColumnMenu} />
                <GridColumn field="codice" title="Codice" width={500} columnMenu={ColumnMenu} />
                <GridColumn field="marca" title="Marca"  columnMenu={ColumnMenu} />
                <GridColumn field="tipologia" title="Tipologia"  columnMenu={ColumnMenu} />
                {/*<GridColumn field="produttore" title="Produttore" width={130}/>*/}
                <GridColumn field="modello_anno" title="Modello / Anno" width={"auto"} columnMenu={ColumnMenu}/>
            </Grid>
        </>

    )
}


export default ExplodedDrawingsGrid;