import {CompositeFilterDescriptor, filterBy, orderBy, SortDescriptor} from "@progress/kendo-data-query";
import {
    Grid, GridColumn, GridColumnMenuFilter, GridColumnMenuProps,
    GridFilterChangeEvent,
    GridPageChangeEvent,
    GridRowClickEvent,
    GridSortChangeEvent
} from "@progress/kendo-react-grid";
import React, {useEffect, useState} from "react";
import {SCREEN_LG, SCREEN_MD, SCREEN_XL, useWindowSize} from "../utils/ScreenSizes";


interface PageState {
    skip: number;
    take: number;
}
const initialFilter: CompositeFilterDescriptor = {
    logic: "and",
    filters: [],
};

interface DrawingPartsOFGridProps {
    maxWidth: string;
    data: any[],
}


const DrawingPartsOFGrid = (props: DrawingPartsOFGridProps) =>{
    const windowSize = useWindowSize();

    const initialDataState: PageState = { skip: 0, take: 20 };
    const [sort, setSort] = useState<SortDescriptor[]>([]);
    const [page, setPage] = useState<PageState>(initialDataState);
    const [filterable, setFilterable] = useState<boolean>(false);
    const [filter, setFilter] = useState(initialFilter);

    const pageChange = (event: GridPageChangeEvent) => {
        setPage(event.page);
    };

    const ColumnMenu = (props: GridColumnMenuProps) => {

        return (
            <div className={"filtro-menu"}>
                <GridColumnMenuFilter

                    {...props}
                    hideSecondFilter={true}
                    expanded={true}
                />
            </div>
        );
    };

    return(
        <Grid
            className={"articles-grid"}
            pageable={{
                buttonCount: 3,
                info: false,
                type: "numeric",
                pageSizes: [10, 25, 50 , 100, 200 ],
                previousNext: true,
            }}
            sortable={true}
            filterable={filterable}
            sort={sort}
            onSortChange={(e: GridSortChangeEvent) => {
                setSort(e.sort);
            }}
            filter={filter}
            skip={page.skip}
            take={page.take}
            onPageChange={pageChange}
            // filterable={true}
            style={{height: "80vh",maxWidth: props.maxWidth,}}
            total={props.data.length}
            onFilterChange={(e: GridFilterChangeEvent) => setFilter(e.filter)}
            // columnVirtualization={true}
            data={orderBy(filterBy(props.data, filter), sort).slice(page.skip, page.take + page.skip)}
        >
            <GridColumn  columnMenu={ColumnMenu} locked={true}  field="posizione" title="Posizione" width={120}/>
            <GridColumn  columnMenu={ColumnMenu} field="numero_parte" title="Numero Parte" width={220}/>
            <GridColumn  columnMenu={ColumnMenu}  field="quantita" title="Quantità" width={120}/>
            <GridColumn  columnMenu={ColumnMenu}  field="descrizione_gb" title="Descrizione" width={400}/>
            {/*<GridColumn field="gruppo_costruttivo_id" title="Gruppo Costruttivo" width={180}/>*/}
            {/*<GridColumn field="produttore" title="Produttore" width={130}/>*/}
            <GridColumn  columnMenu={ColumnMenu}  field="modello_anno" title="Modello Anno" width={600}/>
        </Grid>


    )
}

export default DrawingPartsOFGrid;