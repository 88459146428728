import { api } from "./api";
import {IGetExplodedDrawingsDto} from "../../models/dto/IGetExplodedDrawingsDto";
import {IResultDto} from "../../models/dto/IResultDto";
import {IGetLoggedDto} from "../../models/dto/IGetLoggedDto";
export const authApi = api.injectEndpoints({
    overrideExisting: false,
    endpoints: (build) => ({

        isLoggedUser: build.query<any , null>({
            query: () => ({
                url: "islogged",
            }),
            transformResponse: (baseQueryReturnValue: any, meta) =>
                baseQueryReturnValue,
        }),
        loginManual: build.mutation<any ,  {username: string}>({
            query: ({username}) => ({
                url: "login",
                params: { username: username },
            }),
            transformResponse: (baseQueryReturnValue: any, meta) =>
                baseQueryReturnValue,
        }),
    }),
});

export const {
    useIsLoggedUserQuery,
    useLoginManualMutation
} = authApi;
