import React from "react";
import LOGO from "../img/logo-sabart.png";

const NoAuth = () => {

    // const dispatch = useAppDispatch();
    // const history = useHistory();
    //
    // useEffect(()=>{
    //     if(isSuccess){
    //         history.push("/home");
    //     }
    // },[isSuccess])

    return (
        <div className={"no-auth-page"} style={{textAlign:"center"}}>

            <img className="sabart-logo-no-auth" src={LOGO} />
            <br />
            <br />
            <h2 className={"title-no-auth"}>Autenticazione fallita </h2>
            <br />

            <p className={"text-no-auth"}>
                <label>La preghiamo di effettuare nuovamente l'accesso tramite il portale
                    {/*tramite il <a href={"www.google.com"} target={"_blank"}>link</a>*/}
                </label>
            </p>

            {/*<p className={"text-no-auth"}>*/}
            {/*    <label>Per test interno, inserire hash ricavato da <a href={"https://www.md5.cz/"} target={"_blank"}>https://www.md5.cz/</a>*/}
            {/*        <br />nel formato "metislabMetis@Sabart#202320230324"*/}
            {/*        <br />le ultime 8 cifre sono data odierna in yyyymmdd*/}
            {/*        <br />poi clicca loggati</label>*/}
            {/*</p>*/}

            <br />
            <br />

            {/*<TextBox*/}
            {/*    style={{*/}
            {/*        maxWidth:       "400px",*/}
            {/*        width:          "400px",*/}
            {/*        marginLeft:     "auto",*/}
            {/*        marginRight:    "auto",*/}
            {/*    }}*/}
            {/*    placeholder={"hashmd5"}*/}
            {/*    onChange={(e)=>setToken(e.value?.toString())}*/}
            {/*/>*/}
            {/*<br />*/}
            {/*<br />*/}
            {/*{isLoading && <Loader size={"medium"} type={"infinite-spinner"}/>}*/}
            {/*<br />*/}
            {/*<br />*/}
            {/*<Button onClick={handleClick}>loggati</Button>*/}
            <img className="sabart-logo-no-auth" src={LOGO} />
        </div>
    );
};

export default NoAuth;
