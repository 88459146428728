import React, {useEffect} from 'react';
import {IntlProvider, LocalizationProvider} from "@progress/kendo-react-intl";
import '@progress/kendo-theme-material/dist/all.css';
import {Provider} from "react-redux";
import {RootState, store, useAppSelector} from "./store";
import {
    Route,
    Switch,
    useHistory,
    useLocation,
} from "react-router-dom";
import HomePage from "./pages/home";
import NoAuth from "./pages/NoAuth";
import {useIsLoggedUserQuery} from "./store/api/auth";
import {setupLocalization} from "./l10n";
const selectLoggedIn = (s: RootState) => s.auth.loggedIn
function App() {
    setupLocalization();

    const history = useHistory();
    // const dispatch = useAppDispatch();
    const {data: isLogged, error: dataError} = useIsLoggedUserQuery(null);
    const loggedIn = useAppSelector(selectLoggedIn);
        useEffect(()=>{
            if(isLogged) {
                history.push("/home");
            }
            if(dataError){
                history.push("/no-auth");
            }

        },[isLogged,dataError])


        // if(loggedIn) {
        // }else{
        //     history.push("/no-auth");
        // }

    return (

        <Switch>
            {/*<Route path="/login" component={LoginPage}></Route>*/}
            <Route path="/home" component={HomePage}></Route>
            <Route path="/no-auth" component={NoAuth}></Route>
        </Switch>
    );
}

function AppWrapper() {
  return (
      <LocalizationProvider language="it-IT">
        <IntlProvider locale={"it"}>
          <Provider store={store}>
            <App />
          </Provider>
        </IntlProvider>
      </LocalizationProvider>
  );
}


export default AppWrapper;
