import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector, TypedUseSelectorHook } from "react-redux";
import { setupListeners } from "@reduxjs/toolkit/query";
import { api } from "./api/api";
import auth from "./auth";
import explodedDrawing from "./explodedDrawing";

export const store = configureStore({
    reducer: {
        auth,
        explodedDrawing,
        [api.reducerPath]: api.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat([
            api.middleware,
        ]),
    // middleware: [
    //   ...(getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware),
    //   notificationsMiddleware,
    // ],
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
